<template>
  <div>
    <v-card fluid :loading="loading" :disabled="loading">
      <v-card-title class="tocs_gray_1">
        <v-text-field
          style="max-width: 400px"
          v-model="search"
          append-icon="mdi-magnify"
          :label="$tc('global.buscar')"
          clearable
          outlined
          solo
          flat
          dense
          hide-details
          @clear="buscaCliente"
        ></v-text-field>
        <v-btn-toggle mandatory v-model="status_cliente" class="ml-3">
          <v-btn value="ativo" class="text-caption" height="40px">
            {{ $tc("global.ativo") }}
          </v-btn>
          <v-btn value="inativo" class="text-caption" height="40px">
            {{ $tc("global.inativo") }}
          </v-btn>
        </v-btn-toggle>

        <v-spacer class="d-none d-sm-flex"></v-spacer>
        <DialogCategorias v-if="PCategoriaVisualizar" />
        <v-btn
          class="ml-0 mt-4 mt-sm-0 ml-sm-4"
          :to="{ path: '/clientes/adicionar' }"
          dark
          color="success"
          v-if="PClienteAdicionar"
        >
          <v-icon lefty>mdi-plus</v-icon>
          {{ $tc("global.add") }}
        </v-btn>
      </v-card-title>

      <v-divider></v-divider>
      <v-card-text>
        <v-data-table
          :headers="headers"
          :items="clientes"
          :options.sync="options"
          :server-items-length="totalClientes"
          class="data-tables"
          :class="{ 'data-tables__row-click': PClienteVisualizar }"
          @click:row="goToCliente"
        >
          <template v-slot:item.status="{ item }">
            <span v-if="item.status === 0">inativo</span>
            <span v-if="item.status === 1"> ativo </span>
          </template>
          <template v-slot:item.credito="{ item }">
            <span v-if="item.moeda_id === 2">
              {{ item.credito | guarani }}
            </span>
            <span v-else> {{ item.credito | currency }} </span>
          </template>
        </v-data-table>
      </v-card-text>
    </v-card>
    <v-dialog v-model="clienteDialog">
      <v-card>
        <Cliente :clienteId="clienteId" />
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { getClientes } from "@/api/clientes/clientes.js";
import { mapGetters } from "vuex";

export default {
  name: "ListaClientes",

  components: {
    DialogCategorias: () =>
      import("@/views/dashboard/clientes/components/DialogCategorias.vue"),
    Cliente: () => import("@/views/dashboard/clientes/Cliente.vue"),
  },

  data() {
    return {
      clienteId: null,
      loading: true,
      clientes: [],
      totalClientes: 0,
      options: {
        sortBy: ["nome"],
        sortDesc: [false],
        page: 1,
        itemsPerPage: 15,
      },
      search: "",
      display: false,
      debounce: null,
      status_cliente: "ativo",
      clienteDialog: false,
    };
  },

  computed: {
    ...mapGetters("Componentes", ["getAccess"]),

    PClienteVisualizar() {
      return this.getAccess("Cliente", "visualizar");
    },
    PClienteAdicionar() {
      return this.getAccess("Cliente", "adicionar");
    },
    PCategoriaVisualizar() {
      return this.getAccess("ClienteCategorias", "visualizar");
    },

    status() {
      return [
        {
          text: this.$tc("global.ativo"),
          value: "1",
        },
        {
          text: this.$tc("global.inativo"),
          value: "0",
        },
      ];
    },

    headers() {
      return [
        {
          text: this.$tc("global.nome"),
          value: "nome",
        },
        {
          text: "CI",
          value: "doc_ci",
        },
        {
          text: "RUC",
          value: "doc_ruc",
        },
        {
          text: this.$tc("global.telefone"),
          value: "telefone",
        },
        {
          text: this.$tc("global.cidade"),
          value: "cidade",
        },
        {
          text: this.$tc("global.listadepreco"),
          value: "listas_precos_descricao",
        },
        {
          text: this.$tc("global.credito"),
          value: "credito",
        },
        {
          text: "Status",
          sortable: false,
          align: "center",
          value: "status",
        },
      ];
    },

    url() {
      let queryString = "";
      for (let key in this.$route.query) {
        queryString += `&${key}=${this.$route.query[key]}`;
      }
      if (this.status_cliente) {
        queryString += `&status=${this.status_cliente === "ativo" ? 1 : 0}`;
      }

      return `${queryString}`;
    },
  },

  watch: {
    url() {
      this.getClientes();
    },

    search() {
      if (this.loading) return;
      this.buscaCliente();
    },

    options: {
      handler() {
        this.buscaCliente();
      },
      deep: true,
    },
  },

  props: {
    dialogClientes: {
      type: Boolean,
    },
  },

  methods: {
    goToCliente(item) {
      if (this.PClienteVisualizar) {
        if (this.dialogClientes) {
          this.clienteId = item.id;
          this.clienteDialog = true;
        } else {
          this.$store.commit("UPDATE_DYNAMICCRUMB", item.nome);
          this.$router.push({ path: `/clientes/${item.id}` });
        }
      }
    },

    buscaCliente() {
      const filtro = `&search=${this.search}`;

      clearTimeout(this.debounce);
      this.debounce = setTimeout(async () => {
        await this.getClientes(this.search ? filtro : "");
      }, 500);
    },

    async getClientes(filtro) {
      try {
        this.loading = true;

        const { sortBy, sortDesc, page, itemsPerPage } = this.options;

        const response = await getClientes(
          `?page=${page}&sortBy=${sortBy}&sortDesc=${sortDesc}&itemsPerPage=${itemsPerPage}` +
            this.url +
            (filtro ? filtro : "")
        );

        this.clientes = response.data;
        this.totalClientes = response.total;
        if (this.options.itemsPerPage != Number(response.per_page)) {
          this.options.itemsPerPage = Number(response.per_page);
        }

        this.loading = false;
        return response;
      } catch (error) {
        console.log(error);
        this.loading = false;
      }
    },
  },

  created() {
    // this.getClientes();
  },
};
</script>

<style scoped lang="scss"></style>
